
.navWrapper{
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;

  .navContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    align-items: stretch;
    justify-items: stretch;
    width: 85%;
    max-width: 72rem;
    margin: 2rem auto;
    padding: 0.5rem 0.6rem 0.5rem 1rem;
    border: 1px solid #333;
    border-radius: 2rem;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    .navLogo {
      grid-column: auto / span 3;
      display: grid;
      align-items: stretch;
      justify-items: center;

      a {
          justify-self: left;
          display: grid;
          align-items: center;

          img.logo {         
            height: 4rem;
          }
      }
    }

    .navRight {
      grid-column: auto / span 9;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      align-items: center;
      justify-items: end;
    }
  }
}

@media (max-width: 1280px) {
  .navWrapper{

    .navContainer {
      grid-column-gap: 1rem;
      margin: 1rem auto;
      padding: 0.1rem 0.6rem 0.1rem 1rem;

      .navLogo {
        grid-column: auto / span 4;

        .navLogoImg {
          height: 3.5rem;
        }
      }

      .navRight {
        grid-column: auto / span 8;
      }
    }
  }
}
  
@media (max-width: 720px) {
  .navWrapper{
    border-bottom: 1px solid #333;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    .navContainer {
      grid-column-gap: 0.5rem;
      margin: 0 auto;
      padding: 0.5rem 0;
      border: none;
      border-radius: 0;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;

      .navLogo {
        grid-column: auto / span 6;

        .navLogoImg {
          width: 8rem;
          height: 3rem;
        }
      }

      .navRight {
        grid-column: auto / span 6;
      }

    } 
  }
}