.footerWrapper {
  padding: 2rem 0 4rem;
  width: 100%;

  .footerContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    align-items: stretch;
    justify-items: stretch;
    width: 85%;
    max-width: 72rem;
    margin: 0 auto;
    padding: 2rem 0;
    border-top: 1px solid #444;

    .footerLogo {
      grid-column: auto / span 3;
      display: grid;
      align-items: stretch;
      justify-items: center;

      a {
        justify-self: left;
        display: grid;
        align-items: center;

        img.logo {
          height: 4rem;
        }
      }
    }

    .footerRight {
      grid-column: auto / span 9;
      display: grid;
      grid-template-columns: repeat(6, auto);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      align-items: center;
      justify-items: end;
      justify-content: end;

      .footerRightItem {
        grid-column: auto / span 1;

        svg {
          height: 2rem;
          width: 2rem;
        }
        .kakaoTalkIconSvg {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }

    .footerCompanyInfo {
      grid-column: auto / span 12;
      display: grid;
      grid-template-columns: repeat(12, auto);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      align-items: center;
      justify-items: stretch;
      justify-content: stretch;
      margin-top: 2rem;
      font-size: 0.875rem;

      .footerCompanyInfoItem {
        grid-column: auto / span 12;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 2rem;
        grid-row-gap: 0;
        align-items: center;
        justify-items: stretch;
        justify-content: stretch;
        margin: 0.25rem 0;

        p {
          margin: 0;
          font-size: 0.875rem;
        }

        a {
          text-decoration: underline;
        }

        .footerCompanyInfoSubItem {
          grid-column: auto / span 12;
        }

        .footerCompanyInfoSubItem2Column {
          grid-column: auto / span 2;
        }
      }
      .footerCompanyInfoItem.footerLanguageSelector {
        color: #444440;
      }
    }
  }
}

@media (max-width: 1280px) {
  .footerWrapper {
    .footerContainer {
      .footerLogo {
        img.logo {
          height: 3.5rem;
        }
      }
      .footerCompanyInfo {
        .footerCompanyInfoItem {
          .footerCompanyInfoSubItem2Column {
            grid-column: auto / span 4;
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .footerWrapper {
    padding: 2rem 0 2rem;

    .footerContainer {
      grid-column-gap: 0.5rem;

      .footerLogo {
        grid-column: auto / span 5;

        img.logo {
          height: 3rem;
        }
      }

      .footerRight {
        grid-column: auto / span 7;
        grid-column-gap: 0.5rem;

        .footerRightItem {
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
          .kakaoTalkIconSvg {
            height: 1.2rem;
            width: 1.2rem;
          }
        }
      }

      .footerCompanyInfo {
        .footerCompanyInfoItem {
          grid-column-gap: 0.2rem;
          .footerCompanyInfoSubItem2Column {
            grid-column: auto / span 6;
          }
        }
      }
    }
  }
}
