.landingWrapper {
  display: flex;
  flex-direction: column;
  place-items: center center;

  header.landingHeader {
    padding: 8rem 0 8rem;
    width: 100%;

    p {
      font-size: 2rem;
    }

    .headerContainer {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      justify-content: center;
      width: 90%;
      max-width: 67.5rem;
      margin: 0 auto;
      padding: 8rem 0;
      text-align: center;

      .title {
        grid-column: auto / span 12;
      }

      .subtitle {
        grid-column: auto / span 12;
      }
    }
  }

  main {
    width: 100%;
    max-width: 72rem;

    .mainContainer {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      justify-content: center;
      align-items: center;
      width: 90%;
      max-width: 67.5rem;
      margin: 0 auto;
      padding: 8rem 0;
      text-align: center;

      section {
        position: relative;
        grid-column: auto / span 12;
        margin: 12rem 0;

        h3 {
          display: block;
          position: absolute;
          top: -5rem;
          margin: 0 auto;
          width: 100%;
          opacity: 10%;
          font-size: 23rem;
          line-height: 100%;
          font-weight: 700;
          letter-spacing: -1rem;
          background: -webkit-linear-gradient(#eee, #222);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-stroke: 0.25rem #555;
          -webkit-text-stroke: 0.25rem #555;
        }
      }

      section.ctaSection {
        max-width: 30rem;
        margin: 0 auto 6rem;
      }
    }
  }

  section.ctaSection {
    grid-column: auto / span 12;
    width: auto;
    margin: 0 auto;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    place-items: center center;
  }
}

@media (max-width: 1280px) {
  .landingWrapper {
    header.landingHeader {
      padding: 12rem 0;

      p {
        font-size: 1.5rem;
      }
    }
    main {
      .mainContainer {
        section {
          h3 {
            font-size: 17rem;
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .landingWrapper {
    header.landingHeader {
      padding: 6rem 0 2rem;

      p {
        font-size: 1.25rem;
      }

      .headerContainer {
        padding: 8rem 0;

        .title {
          grid-column: auto / span 12;

          h1 {
            font-size: 1.8rem;
          }
        }

        .subtitle {
          grid-column: auto / span 12;
        }
      }
    }

    main {
      .mainContainer {
        section {
          margin: 8rem 0;

          h3 {
            top: -5.5rem;
            font-size: 8rem;
          }
        }
      }
    }

    section.ctaSection {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
